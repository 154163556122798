@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=PT+Mono');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Inter');

@import "./static/assets/fonts/stylesheet.css";


html {
    height: 100%;
    width: 100%;
    zoom: 80%;
}

body {
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.App {
    height: 100%;
    width: 100%;
}

div[class^="MuiTablePagination"] * {
    margin-bottom: 0;
}

* {
    font-family: Arial, sans-serif;
    //letter-spacing: 0.5px;
}


@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}


#loadingTitle {
    font-family: 'Inter', sans-serif;    
    font-weight: 1000;
    margin-bottom: 0.5em;
}

